import axiosService from "@services/axiosService";
import { IDataResponse } from "@services/interface/IDataResponse";
import "reflect-metadata";
import { injectable } from "inversify";
import {
  IEmployee,
  IEmployeeUpdate,
  IGetAllEmployeeParams,
  IRetireEmployeeParams,
} from "@/models/setting/Employee";
import { IPagination } from "@/models/Pagination";
import { normalizeEmployeeList } from "@/datasource/services/nomalizeModel/normalizeEmployeeList";

export interface ISettingEmployeeService {
  updateEmployee(
    params: IEmployeeUpdate,
    id: any
  ): Promise<IDataResponse<IEmployee>>;
  updateEmployeeTargetPoint(
    id: number,
    data: any
  ): Promise<IDataResponse<IEmployee>>;
  getEmployee(id: any): Promise<IDataResponse<IEmployee>>;
  createEmployee(params: IEmployeeUpdate): Promise<IDataResponse<IEmployee>>;
  deleteEmployee(id: any): Promise<IDataResponse<any>>;
  retireEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>>;
  getAllEmployee(
    params: IGetAllEmployeeParams
  ): Promise<IDataResponse<{ data: IEmployee[]; pagination: IPagination }>>;
  recoverEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>>;
  updatePolicy(id: any, params: any): Promise<IDataResponse<any>>;
  getEmployeeTask(params: any): Promise<IDataResponse<any>>;
  getEmployeeTaskTimeLine(params: any): Promise<IDataResponse<any>>;
  getEmployeeTaskActualHours(params: any): Promise<IDataResponse<any>>;
  retireEmployee(params: IRetireEmployeeParams, id: any): Promise<IDataResponse<any>>;
  getAllEmployee(params: IGetAllEmployeeParams): Promise<IDataResponse<{data: IEmployee[]; pagination: IPagination}>>;
  recoverEmployee(params: IRetireEmployeeParams, id: any): Promise<IDataResponse<any>>;
  logOut(id: any): Promise<IDataResponse<any>>;
  disabledEdit(id: any): Promise<IDataResponse<any>>;
  getOriginalLog(id: any): Promise<IDataResponse<any>>;
  updateLog(data: any): Promise<IDataResponse<any>>;
  registerNewQRToken(id: string): Promise<IDataResponse<any>>;
  getTaskEmployee(id: any, params: any): Promise<IDataResponse<any>>;
}

@injectable()
export class SettingEmployeeService implements ISettingEmployeeService {
  private prefix: string;
  constructor() {
    this.prefix = "/employees";
  }

  async getOriginalLog(id: any): Promise<IDataResponse<IEmployee>> {
    return await axiosService.get(`${this.prefix}/tasks/timelines/logs/${id}/get-original-log`);
  }

  async updateLog(data: any) : Promise<IDataResponse<IEmployee>> {
    return await axiosService.post(`${this.prefix}/write-log`, data);
  }

  async getEmployeeTaskActualHours(params: any): Promise<IDataResponse<IEmployee>> {
    return await axiosService.get(`${this.prefix}/tasks/statistics`, { params });
  }

  async updatePolicy(id: any, params: any): Promise<IDataResponse<IEmployee>> {
    return await axiosService.put(`${this.prefix}/${id}/policy`, params);
  }

  async getEmployeeTaskTimeLine(params: any): Promise<IDataResponse<IEmployee>> {
    return await axiosService.get(`${this.prefix}/tasks/timelines`, { params });
  }

  async getEmployeeTask(params: any): Promise<IDataResponse<IEmployee>> {
    return await axiosService.get(`${this.prefix}/tasks`, { params });
  }

  async logOut( id: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/${id}/clear-login`);
  }
  async disabledEdit(id: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/${id}/clear-input`);
  }
  async getEmployee(id: any): Promise<IDataResponse<IEmployee>> {
    const response = await axiosService.get(`${this.prefix}/${id}`);
    return response;
  }
  async updateEmployee(
    params: IEmployeeUpdate,
    id: any
  ): Promise<IDataResponse<IEmployee>> {
    delete params.qr_token;
    const response = await axiosService.put(`${this.prefix}/${id}`, params);
    return response;
  }
  async updateEmployeeTargetPoint(
    id: number,
    data: any
  ): Promise<IDataResponse<IEmployee>> {
    const response = await axiosService.put(
      `${this.prefix}/${id}/target_point`,
      data
    );
    return response;
  }

  async createEmployee(
    params: IEmployeeUpdate
  ): Promise<IDataResponse<IEmployee>> {
    const response = await axiosService.post(`${this.prefix}`, params);
    return response;
  }
  async deleteEmployee(id: any): Promise<IDataResponse<any>> {
    const response = await axiosService.delete(`${this.prefix}/${id}`);
    return response;
  }
  async retireEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(
      `${this.prefix}/${id}/retire`,
      params
    );
    return response;
  }
  async getAllEmployee(
    params: IGetAllEmployeeParams
  ): Promise<IDataResponse<{ data: IEmployee[]; pagination: IPagination }>> {
    const response = await axiosService.get(`${this.prefix}`, { params });
    //normalize data
    if (params.limit == -1) {
      return response;
    } else if (response.data && response.success) {
      const data = normalizeEmployeeList(response.data);
      response.data = data;
    }

    return response;
  }
  async recoverEmployee(
    params: IRetireEmployeeParams,
    id: any
  ): Promise<IDataResponse<any>> {
    const response = await axiosService.put(
      `${this.prefix}/${id}/recover`,
      params
    );
    return response;
  }

  async registerNewQRToken( id: any): Promise<IDataResponse<any>> {
    return await axiosService.post(`${this.prefix}/${id}/register-new-qr-token`);
  }

  async getTaskEmployee(id: any, params: any): Promise<IDataResponse<IEmployee>> {
    return await axiosService.get(`${this.prefix}/tasks/timelines/employee/${id}`, { params });
  }
}
